<template>
    <div class="container">
      <div class="row">
      <div class="col-lg-3">
          <img alt="Vue logo" src="@/assets/logo.png">
      </div>
      <div class="col-lg-9 text-center">
        <h1>Catálogo Digital de Produtos</h1>
        <h3>Campanhas Comerciais</h3>
        <p>Aqui você encontra nossa linha de produtos, considerando apenas os produtos com estoque e disponíveis para pronta entrega!!! Use esse site como base para montar seus pedidos com 100% de aproveitamento.</p>
        <p>Conheça também nossa plataforma de compras B2B <a href="https://tokeecrie.meuspedidos.com.br/">clicando aqui</a> e monte você mesmo seus pedidos.</p>
        <p>Em caso de dúvidas entre em contato com nosso administrativo de vendas.<br>
        e-mail: <a  href="emailto:comercial@tokecrie.com.br">comercial@tokecrie.com.br</a><br>
        tel.: <a  href="tel:+5511994581085">(11) 99458-1085</a></p>
        <button type="button" class="btn btn-primary" @click.stop="gotoHome">Acessar</button>
      </div>
  </div>
    </div>
</template>
<script>
export default {
  name: 'Disclaimer',
  methods:{
      gotoHome(){
          this.$router.push('/home');
      }
  }
}
</script>
<style scoped>
  .container{
    margin-top: 50px!important;
  }

</style>
