<template>
    <div>
        <card>
        <template>
            <div class="text-right">
                    <base-button type="primary" @click="$emit('return-click')"> Voltar </base-button>
                </div>
            <div class="text-center">
            <img  v-lazy="item.image_1!==''?item.image_1:'/img/theme/placeholder.png'"  
                class="card-img-top" 
                :alt="item.title" 
                style="max-height: 500px; max-width: 500px;"
                @click="imageModal.img = item.image_1; imageModal.show = item.image_1!==''">
            </div>
            <div class="card-body">
                <h5 class="card-title">{{item.title}}</h5>
                <p class="card-text">
                    <small class="font-weight-bolder">Artigo</small><br/>
                    {{item.sku}}</p>
                <p class="card-text">
                    <small class="font-weight-bolder">Subgrupo</small><br/>
                    {{item.subcategory}}</p>
                <p class="card-text">
                    <small class="font-weight-bolder">Linha de Produtos</small><br/>
                    {{item.brand}}</p>
                    <div class="card-text">
                        <small class="font-weight-bolder">Descrição</small><br/>
                        <p class="text-break" v-html="item.description"></p>
                </div>
                <div>
                    <img @click="imageModal.img = item.image_2; imageModal.show = true" v-lazy="item.image_2" class="img-thumbnail" v-show="item.image_2!==''">
                    <img @click="imageModal.img = item.image_3; imageModal.show = true" v-lazy="item.image_3" class="img-thumbnail" v-show="item.image_3!==''">
                    <img @click="imageModal.img = item.image_4; imageModal.show = true" v-lazy="item.image_4" class="img-thumbnail" v-show="item.image_4!==''">
                    <img @click="imageModal.img = item.image_5; imageModal.show = true" v-lazy="item.image_5" class="img-thumbnail" v-show="item.image_5!==''">
                    <img @click="imageModal.img = item.image_6; imageModal.show = true" v-lazy="item.image_6" class="img-thumbnail" v-show="item.image_6!==''">
                </div>
                <div class="text-right">
                    <base-button type="primary" @click="$emit('return-click')"> Voltar </base-button>
                </div>
            </div>
        </template>
    </card>
    <modal-custom 
        :show.sync="imageModal.show"
        modal-classes="modal-dialog-centered modal-xl">
            <img :src="imageModal.img" style=" max-width: 100%;max-height: 100%;">
    </modal-custom>
    </div>
    
</template>
<script>
import Card from '@/components/Card';
import ModalCustom from '@/components/ModalCustom';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export default {
    name: 'CatalogItem',
    components:{
       Card,ModalCustom
    },
    props:{
      item: Object
    },
    data(){
        return {
            imageModal:{
                show:false,
                img:''
            }
        }
    }
};
</script>
<style scoped>
.img-thumbnail{
        height: 200px;
    }
</style>
