<template>
  <div>
    <news></news>
  </div>
</template>

<script>
// @ is an alias to /src

import News from "./components/News";
export default {
  name: 'Home',
  components:{
      News
  },
  data(){
    return {
      news:[
        {id:'1', user: '@Estoque em tempo real', notice:'Bem vindo ao nosso catálogo online de produtos para entrega imediata. Esse link foi construído para você e é atualizado em tempo real com nosso estoque para exibir apenas os produtos que temos disponível para pronta entrega. Aproveite para montar os seus pedidos com 100% de aproveitamento.'},
        {id:'2', user: '@Monte seus pedidos com 100% de aproveitamento', notice: 'Use esse site como base para montar seus pedidos e enviar para seu representante ou entre direto em nosso portal de vendas B2B clicando aqui e monte você mesmo seus pedidos. https://tokeecrie.meuspedidos.com.br/' }
      ]
    }
  }
}
</script>
