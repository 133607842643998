/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import sitemapMiddleware from './plugins/sitemapMiddleware';
import filters from './components/filters';
import './registerServiceWorker'

Vue.config.productionTip = false;
export const bus = new Vue();

Vue.use(Argon);
Vue.filter('capitalize', (value)=> filters.capitalize(value));
Vue.filter('slug', (value)=> filters.slug(value));
Vue.use('/sitemap.xml', sitemapMiddleware());
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
