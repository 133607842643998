<template>
  <ul class="list-group list-group-flush">
    <li
      class="list-group-item"
      v-for="(subcategory, i) in subcategories"
      :key="i"
    >
      <h3>{{ subcategory.subcategory }}</h3>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item catalog-list-item"
          :class="[item.actived == '1' ? 'item-allowed' : 'item-not-allowed']"
          v-for="item in subcategory.products"
          :key="item.id"
        >
          <div class="row">
            <div class="col-sm catalog-list-item-col-left">
              <div class="media" >
                <img
                  class="mr-lg-5 mr-1 mb-0 catalog-img"
                  v-lazy="
                    item.image_1 !== ''
                      ? item.image_1
                      : '/img/theme/placeholder.png'
                  "
                  :alt="item.title"  @click.stop="$emit('item-click', item)"
                />
                <div class="media-body">
                  <h5 class="mt-0" @click.stop="$emit('item-click', item)">
                    {{ item.title }}
                    <span v-if="item.cvp == 'OUTLET'" class="badge badge-dark">
                      <i class="ni ni-tag"></i> Outlet
                    </span>
                  </h5>
                  <div class="row"  @click.stop="$emit('item-click', item)">
                    <div class="col-sm">
                      <small>Código (SKU)</small>
                      <br />
                      {{ item.sku }}
                    </div>
                    <div class="col-sm">
                      <small>Unidade de Venda</small>
                      <br />
                      <SalesUnitValue
                        :unit="item.salesUnit"
                        :qty="item.packQuantity"
                      />
                    </div>
                  </div>
                  <div class="row" @click.stop="$emit('item-click', item)">
                    <div class="col">
                      <small>Técnicas: {{ item.technique }}</small>
                    </div>
                  </div>
                  <div class="row"><div class="col"><a :href="getLinkLoja(item)" target="_blank" rel="noopener noreferrer">Ver na loja</a></div></div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import SalesUnitValue from "./SalesUnitValue";
import Icon from "../../components/Icon";
export default {
  name: "Catalog",
  components: {
    SalesUnitValue,
    Icon,
  },
  computed: {
    itemAccess: () => {
      return "item-allowed";
    },
  },
  methods: {
    filterActivedItens: (item) => {
      return item.actived == "1";
    },
    getLinkLoja: (item)=>{
      let busca = `${item.title}`.replace(/(\s)/gm, '+')
      return 'https://loja.tokecrie.com.br/loja/busca.php?loja=773991&palavra_busca='+busca
    }
  },
  props: {
    subcategories: Array,
  },
};
</script>
<style scoped>
.item-allowed {
  cursor: pointer;
}

.item-not-allowed {
  cursor: pointer;
}
</style>
