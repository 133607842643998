import Vue from "vue";
import Router from "vue-router";
import Header from "./layout/tec/tecHeader";
import Footer from "./layout/tec/tecFooter";
import Disclaimer from "./views/Disclaimer.vue";

import Home from "./views/Home.vue";
import Guide from "./views/Guide.vue"
import Cvp from "./views/Cvp.vue"

Vue.use(Router);

export default new Router({

  routes: [
    {
      path: "/",
      name: "disclaimer",
      components: {
        default: Disclaimer,
      }
    },
    {
      path: "/home",
      name: "home",
      components: {
        header: Header,
        default: Home,
        footer: Footer
      }
    },
    {
      path: "/outlet",
      name: "outlet",
      components: {
        header: Header,
        default: Cvp,
        footer: Footer
      }
    },
    {
      path: "/search",
      name: "search",
      components: {
        header: Header,
        default: Guide,
        footer: Footer
      }
    },
    {
      path: "/:guide",
      name: "guide",
      components: {
        header: Header,
        default: Guide,
        footer: Footer
      }
    }
  ]
});
