<template>
    <footer class="footer has-cards">
        
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-6">
                    <h4 class="mb-0 font-weight-light">Vamos superar a COVID-19? #FicaEmCasa</h4>
                </div>
                <div class="col-lg-6 text-lg-center btn-wrapper">
                    <a target="_blank" href="https://www.facebook.com/TOKEeCRIE/"
                       class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Curta!">
                        <i class="fa fa-facebook"></i>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/tokeecrie/"
                       class="btn btn-neutral btn-icon-only btn-instagram btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Siga">
                        <i class="fa fa-instagram"></i>
                    </a>
                    <a target="_blank" href="https://www.youtube.com/CanalTokeeCrie"
                       class="btn btn-neutral btn-icon-only btn-youtube btn-lg btn-round" data-toggle="tooltip"
                       data-original-title="Siga">
                        <i class="fa fa-youtube"></i>
                    </a>
                    <a target="_blank" href="https://br.pinterest.com/tokeecrie/"
                       class="btn btn-neutral btn-icon-only btn-pinterest btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Siga">
                        <i class="fa fa-pinterest"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        <a href="https://www.tokecrie.com.br" target="_blank" rel="noopener">#TecLovers</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <a href="https://www.tokecrie.com.br/institucional" class="nav-link" target="_blank">Institucional</a>
                        </li>                        
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'starter-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
