const filters = {
  capitalize: function (value) {
    if (!value) return '';
    value = value.toString().split(' ').map((el)=> el.charAt(0).toUpperCase() + el.slice(1).toLowerCase()).join(' ') ;
    return value;
  },
  slug: function (value) {
    if (!value) return '';
    value = value.toString().split(' ').map((el)=> el.toLowerCase()).join('-') ;
    return value;
  }
};
export default filters;
