<template>
  <section class="section-hero section-shaped my-0">
    <div class="shape shape-style-1 shape-primary">
      <span class="span-150"></span>
      <span class="span-50"></span>
      <span class="span-50"></span>
      <span class="span-75"></span>
      <span class="span-100"></span>
      <span class="span-75"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
    </div>
    <div class="container shape-container d-flex align-items-top">
      <div class="col px-0">
        <div class="row">
          <div class="col-lg">
            <div
              class="
                d-flex
                align-items-center
                p-3
                my-3
                bg-primary
                rounded
                box-shadow
                text-white
              "
            >
              <img
                class="mr-3"
                src="@/assets/logo.png"
                alt=""
                width="48"
                height="48"
              />
              <div class="lh-100">
                <h3 class="mb-0 lh-100 text-white">
                  Catálogo Digital Toke e Crie!
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <div class="my-3 p-3 bg-white rounded box-shadow">
              <h6 class="border-bottom border-gray pb-2 mb-0">
                Últimas notícias
              </h6>
              <div class="media text-muted pt-3">
                <img
                  data-src="holder.js/32x32?theme=thumb&amp;bg=007bff&amp;fg=007bff&amp;size=1"
                  alt="32x32"
                  class="mr-2 rounded"
                  style="width: 32px; height: 32px"
                  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2232%22%20height%3D%2232%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1724813055b%20text%20%7B%20fill%3A%23007bff%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A2pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1724813055b%22%3E%3Crect%20width%3D%2232%22%20height%3D%2232%22%20fill%3D%22%23007bff%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2211.5390625%22%20y%3D%2216.9%22%3E32x32%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                  data-holder-rendered="true"
                />
                <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                  <strong class="d-block text-gray-dark">@100% Pronta entrega!</strong>
                  Bem vindo! Esse link foi construído para você e é atualizado em tempo real com nosso estoque para exibir apenas os produtos que temos disponível para pronta entrega. Aproveite para montar os seus pedidos com 100% de aproveitamento.
                </p>
              </div>
              <div class="media text-muted pt-3">
                <img
                  data-src="holder.js/32x32?theme=thumb&amp;bg=007bff&amp;fg=007bff&amp;size=1"
                  alt="32x32"
                  class="mr-2 rounded"
                  style="width: 32px; height: 32px"
                  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2232%22%20height%3D%2232%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1724813055b%20text%20%7B%20fill%3A%23007bff%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A2pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1724813055b%22%3E%3Crect%20width%3D%2232%22%20height%3D%2232%22%20fill%3D%22%23007bff%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2211.5390625%22%20y%3D%2216.9%22%3E32x32%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                  data-holder-rendered="true"
                />
                <p class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                  <strong class="d-block text-gray-dark">@Campanhas e Pré-venda!</strong>
                  No link campanhas fique sempre por dentro das oportunidades para fazer ótimos negócios! Seja com Pronta entrega, ou com a Pré-venda, garantindo seu abastecimento com itens sucessos de mercado que estão prestes a chegar em nosso estoque. 
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "News",
  data() {
    return {
      news: [
        {
          id: "1",
          user: "@Estoque em tempo real",
          notice:
            "",
        },
        {
          id: "2",
          user: "@Monte seus pedidos com 100% de aproveitamento",
          notice:
            "",
        },
      ],
    };
  },
};
</script>
<style>
</style>
