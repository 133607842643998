<template>
  <header class="header-global">
    <base-nav
      class="navbar-main"
      transparent
      type="primary"
      effect="light"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/home">
        <img src="@/assets/logo.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="#">
            <img src="@/assets/logo.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu">oi</close-button>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class="nav-item">
          <a
            href="https://tokeecrie.meuspedidos.com.br/destaques"
            class="nav-link" >
            <i class="ni ni-tag"></i>
            <span class="nav-link-inner--text">Campanhas</span>
          </a>
        </li>
        <base-dropdown tag="li" class="nav-item" v-if="categorias">
          <a
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-collection"></i>
            <span class="nav-link-inner--text">Catálogos</span>
          </a>
          <router-link
            :to="item.url"
            class="dropdown-item"
            v-for="item in categorias"
            :key="item.title"
            >{{ item.title | capitalize }}</router-link
          >
        </base-dropdown>
        <li class="nav-item">
          <router-link :to="'/outlet'" class="nav-link">
            <i class="ni ni-tag"></i>
            <span class="nav-link-inner--text">Outlet</span>
          </router-link>
        </li>
      </ul>
      <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex">
        <form class="form-inline my-2 my-lg-0" @submit.prevent="search">
          <input
            class="form-control mr-sm-2"
            type="search"
            placeholder="Código, Nome, Técnica"
            aria-label="Código, Nome, Técnica"
            v-model="searchTerm"
          />
          <button class="btn btn-outline-white my-2 my-sm-0" type="submit">
            Buscar
          </button>
        </form>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import ft from "node-fetch";
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import { bus } from "@/main";
import { LZHB_API } from "@/config";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
  data() {
    return {
      categorias: null,
      campanhas: null,
      loading: true,
      searchTerm: "",
    };
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show;
    },
    search(event) {
      bus.$emit("BUSCAR", this.searchTerm);
      try {
        this.$router.push({ path: "search", query: { q: this.searchTerm } });
      } catch (error) {}
      this.searchTerm = "";
    },
    fetchData() {
      ft(`${LZHB_API}/categorias`)
        .then((res) => res.json())
        .then((data) => {
          this.categorias = data;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style>
</style>
