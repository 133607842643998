<template>
  <div>{{salesUnity(qty, unit)}}</div>
</template>

<script>
export default {
    name: 'SalesUnitValue',
    props:{
        unit:String,
        qty:[Number, String]
    },
    methods:{
        salesUnity:(qty, unit)=>{
            try{
                qty = parseInt(qty);
                return qty > 1? `${unit} c/ ${qty} un`: `${unit}`;
            }catch(error){
                return ''
            }
        }
    }
}
</script>

<style>

</style>