<template>
  <div class="list-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card
          v-if="loading"
          type="secondary"
          shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0"
        >
          <template>
            <div class="text-muted text-center mb-3">Carregando</div>
          </template>
        </card>
        <div v-if="error" class="error">{{ error }}</div>
        <template v-if="subcategories && !itemSelected">
          <div class="row">
            <div class="col">
              <h3 class="text-white">{{ cvp | capitalize }}</h3>
            </div>
            <div class="col d-flex justify-content-end">
              <BasePagination
                :total="total"
                :perPage="pageSize"
                :value="page"
                :pageCount="totalPages"
                @input="gotoPage"
                size="sm"
              />
            </div>
          </div>
          <catalog :subcategories="subcategories" @item-click="gotoItem" />
          <div class="d-flex justify-content-end">
            <BasePagination
              :total="total"
              :perPage="pageSize"
              :value="page"
              :pageCount="totalPages"
              @input="gotoPage"
              size="sm"
            />
          </div>
        </template>
        <CatalogItem
          v-if="itemSelected"
          :item="itemSelected"
          @return-click="itemSelected = null"
        />
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
import ft from "node-fetch";
import moment from "moment";
import Catalog from "./components/Catalog";
import CatalogItem from "./components/CatalogItem";
import BasePagination from "../components/BasePagination";
import { LZHB_API } from "@/config";

export default {
  name: "Cvp",
  components: {
    Catalog,
    BasePagination,
    CatalogItem,
  },
  methods: {
    gotoItem(item) {
      this.itemSelected = item;
    },
    gotoPage(el) {
      this.fetchData(this.cvp, el);
    },
    fetchData(cvp, page) {
      this.error = this.subcategories = null;
      this.loading = true;
      let now = moment();
      this.cvp = cvp;
      this.page = page ? page : 1;
      ft(`${LZHB_API}/produtos?cvp=OUTLET&page=${this.page}`)
        .then((res) => res.json())
        .then((res) => {
          this.total = Number(res.paginator.total);
          this.totalPages = Number(res.paginator.total_pages);
          this.page = Number(res.paginator.page);
          this.subcategories = res.data;
          this.loading = false;
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData("outlet");
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData("outlet");
    });
  },
  data() {
    return {
      lastUpdate: null,
      error: null,
      loading: true,
      subcategories: null,
      cvp: null,
      page: 1,
      pageSize: 20,
      total: 0,
      itemSelected: null,
      totalPages: 0,
    };
  },
};
</script>
<style >
</style>
